import Link from "next/link";
import styled from "styled-components";
import { useTheme } from "components/services/themeContext";
import asyncComponent from "components/split/asyncComponent";
import Script from "next/script";
const Logo = asyncComponent(() => import("components/general/logo"));

const StyledWrapper = styled.div`
  border-top: 1px solid var(--raised-background-color);
  display: flex;
  justify-content: center;
  height: 220px;
  overflow: hidden;
  @media screen and (max-width: 992px) {
    height: auto;
    padding: 2rem 0;
    font-size: 12px;
  }
  font-family: "Open Sans", sans-serif;
`;
const StyledContainer = styled.div`
  width: calc(100% - 6rem);
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Footer = () => {
  const copyrightString = `© ${new Date().getFullYear()} Redact - All rights reserved`;
  const { theme } = useTheme();
  return (
    <StyledWrapper>
      <StyledContainer>
        <div className="flex flex-col mt-16">
          <div className="-mt-10 flex flex-col-reverse sm:flex-row">
              <div className="flex ml-16 mt-10 md:ml-5 md:mt-1">
                <Logo size="w-auto h-16 sm:h-[2.5rem]"/>
              </div>
            <div className="grid grid-cols-2 -ml-32 sm:flex sm:ml-0">
              <div className="flex-col">
                <p className={`flex ml-24 text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}>
                    Company
                </p>
                <Link 
                  href="https://redact.dev/blog"
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>
                    Blog
                </Link>
                <Link 
                  href="/contact" 
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 mt-3 sm:mt-4 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>
                    Contact Us
                </Link>
                <Link 
                  href="https://aioptout.co" 
                  target="_blank"
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 mt-3 sm:mt-4 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>
                    AI Opt Out
                </Link>
              </div>
              <div className="flex-col">
                <p className={`flex ml-24 text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}>
                    Media
                </p>
                <Link 
                  href="/affiliates" 
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Affiliates</Link>
                <Link 
                  href="/press-kit" 
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 mt-3 sm:mt-4 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Press Kit</Link>
              </div>
              <div className="flex-col w-80">
                <p className={`flex ml-24 text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}>
                    Legal
                </p>
                <Link 
                  href="/privacy" 
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Privacy Policy</Link>
                <Link 
                  href="/terms" 
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 mt-3 sm:mt-4 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Terms & Conditions</Link>
              </div>
              <div className="flex-col">
                <p className={`flex ml-24 text-lg sm:ml-5 md:ml-32 md:mt-1 ${theme === "light" ? "text-black" : "text-white"} font-bold`}>
                    Socials
                </p>
                <Link 
                  href="https://x.com/redactdev" 
                  target="_blank"
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Twitter{<span class="icon-[fluent--open-12-regular] ml-1 mt-1"></span>}</Link>
                <Link 
                  href="https://www.reddit.com/r/redact/" 
                  target="_blank"
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 mt-3 sm:mt-4 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Reddit{<span class="icon-[fluent--open-12-regular] ml-1 mt-1"></span>}</Link>
                <Link 
                  href="https://discord.com/invite/PbyTFBEhtV" 
                  target="_blank"
                  style={{ textDecoration: 'none' }} 
                  className={`flex ml-24 text-lg sm:ml-5 mt-3 sm:mt-4 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 hover:opacity-100 transition ease-in-out`}>Discord{<span class="icon-[fluent--open-12-regular] ml-1 mt-1"></span>}</Link>
              </div>
            </div>
          </div>
          <div className="flex">
            <p className={`text-base ml-5 mt-3 sm:ml-0 sm:mt-5 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65`}>{copyrightString}</p>
          </div>
        </div>
          <Script strategy="afterInteractive" id="diffuser">
            {`
              (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
              vgo('setAccount', '613086146');
              vgo('setTrackByDefault', true);

              vgo('process');   
            `}
          </Script>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Footer;
