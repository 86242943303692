export const bannerData = {
  bannerText: (
    <p>
      Black Friday Sale. <span className="underline">Get the deal</span>
    </p>
  ),
  bannerTextMobile: (
    <p>
      Black Friday Sale. <span className="underline">Get the deal</span>
    </p>
  ),
  path: "/blackfriday", //if its Redact.dev just use slash, if its a complete url put it in this format "https://www.stripe.com/redact"
  isActive: false,
  showsExpiration: false,
  showsExpirationMobile: false,
  expirationDate: "2024-12-03T08:00:00Z", //YYYY-MM-DD T HH:MM:SS UTC
};
