import { DateTime } from "luxon";
import Link from "next/link";
import { useEffect, useState } from "react";
import { bannerData } from "src/data/bannerData";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";

const StyledWrapper = styled.div`
  opacity: 90%;
  position: fixed;
  width: 100%;
  z-index: 100;
  display: ${(props) => (props["data-active"] ? "flex" : "none")};
  @media screen and (max-width: 1140px) {
    bottom: 5px;
    height: ${bannerData.showsExpirationMobile ? "6rem" : "4rem"};
    opacity: 95%;
    border-radius: 5px;
  }
  @media screen and (min-width: 1140px) {
    top: 0;
  }
  background: var(--primary-color);
  color: var(--text-color);
  box-shadow:
    0px 0px 0px 1px var(--shadow-color, #5630b2),
    0 4px 0px 1px var(--shadow-color, #5630b2);
`;

const StyledContainer = styled.a`
  text-decoration: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 3rem;
  font-size: 1.3rem;
  @media screen and (max-width: 1140px) {
    font-size: 1rem;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  color: var(--text-color);

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const StyledTimer = styled.p`
  display: ${bannerData.showsExpiration ? "flex" : "none"};
  font-size: 1.3rem;
  @media screen and (max-width: 1140px) {
    display: ${bannerData.showsExpirationMobile ? "flex" : "none"};
    font-size: 1rem;
    margin-top: 0.75rem;
    height: 0px;
  }
`;

const StyledBannerText = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 3rem;
  @media screen and (max-width: 1140px) {
    font-size: 1rem;
    margin-left: 0px;
    width: 90%;
    margin-top: ${bannerData.showsExpirationMobile ? "-0.5rem" : "-0.1rem"};
  }
`;

const StyledImage = styled.img`
  display: ${bannerData.showsExpiration ? "flex" : "none"};
  margin-right: 5px;
  @media screen and (max-width: 992px) {
    display: ${bannerData.showsExpirationMobile ? "flex" : "none"};
    margin-top: 10px;
    margin-left: 10px;
  }
`;

const StyledExitButton = styled.img`
  position: absolute;
  color: var(--text-color);
  margin-left: 95%;
  top: 15px;
  cursor: pointer;
  bottom: 20px;
  @media screen and (max-width: 1140px) {
    margin-left: ${bannerData.showsExpirationMobile ? "90%" : "92%"};
  }
`;

const Banner = ({ dark }) => {
  const expirationDate = DateTime.fromISO(bannerData.expirationDate);
  const [countdown, setCountdown] = useState(expirationDate.diffNow());

  const [activeBanner, setActiveBanner] = useState(true);

  const handleBannerActivity = () => {
    setActiveBanner(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveBanner(bannerData.isActive && expirationDate > DateTime.now());
    }
    const intervalId = setInterval(() => {
      if (expirationDate <= DateTime.now()) {
        setActiveBanner(false);
        clearInterval(intervalId);
      }
      setCountdown(expirationDate.diffNow());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { width } = useWindowSize();

  if (!bannerData.isActive) {
    return null;
  }

  return (
    <>
      <StyledWrapper data-dark={dark} data-active={activeBanner}>
        <a onClick={handleBannerActivity}>
          <StyledExitButton
            src="/images/svgs/exit_banner.svg"
            alt="mobile preview"
            height={16}
            width={16}
          />
        </a>
        <Link href={bannerData.path} passHref legacyBehavior style={{ textDecoration: "none" }}>
          <StyledContainer data-active={activeBanner}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <StyledImage
                src="/images/svgs/clock.svg"
                alt="mobile preview"
                height={20}
                width={20}
              />
              <StyledTimer suppressHydrationWarning>
                Expires in {countdown?.toFormat("hh':'mm':'ss'")}
              </StyledTimer>
            </div>
            <StyledBannerText>
              <div className="mr-5 flex h-[28px] w-[100px] items-center justify-center rounded-xl bg-yellow-400 font-bold text-black">
                25% OFF
              </div>
              {width > 1140 ? bannerData.bannerText : bannerData.bannerTextMobile}
            </StyledBannerText>
          </StyledContainer>
        </Link>
        <span class="icon-[openmoji--flag-russia] invisible absolute"></span>
        <span class="icon-[openmoji--flag-turkey] invisible absolute"></span>
      </StyledWrapper>
      {activeBanner && <div style={{ marginTop: "25px" }} />}
    </>
  );
};

export default Banner;
